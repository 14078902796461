$color-bg: #efeeee;
$color-shadow: #b6b4b4;
$color-white: #fff;
$color-red: #ae1100;
$ruler: 16px;
.skills-section {
  margin-bottom: 120px;
  .skills-content {
    .skill-title {
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      margin-top: 30px;
    }
    .skills-infos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .skill-group {
        .skill {
          width: 100px;
          height: 100px;
          margin: 20px;
          box-sizing: border-box;
          box-shadow: -4px -4px 13px $color-white, 4px 4px 13px $color-shadow;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
        }
        .skill-info {
          color: rgb(51, 51, 51);
          text-align: center;
        }
      }
    }
  }
}
