.title_container {
  margin-top: 60px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  text-align: center;

  .title_background {
    background-image: url("../images/SakuraCity.gif");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .title_filter {
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .title_contents {
    text-align: center;
    color: white;
    z-index: 1;
    text-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    h1 {
      font-size: 60px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    h4 {
      margin-top: 30px;
    }
    .line {
      background-color: orange;
      width: 200px;
      height: 16px;
    }
  }
}
