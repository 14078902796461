$color-bg: #efeeee;
$color-shadow: #b6b4b4;
$color-white: #fff;
$color-red: #ae1100;
$ruler: 16px;
.portfolio-section {
  .portfolio-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 120px;

    .portfolio-info-area {
      min-height: 200px;
      flex-basis: 50%;
      padding: 7px;
      box-sizing: border-box;

      .portfolio-info {
        border: 1px solid #efeeee;
        height: 100%;
        border-radius: 10px;
        box-shadow: -7px -7px 13px $color-white, 4px 4px 13px $color-shadow;
        position: relative;
        padding: 20px;
        box-sizing: border-box;

        .picture-area {
          height: 300px;
          background-image: url("../images/SakuraCity.gif");
          border-radius: 10px 10px 0px 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          top: 0;
          left: 0;
          width: 100%;
          position: absolute;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          margin-top: 280px;
          font-size: 30px;
          font-weight: bold;
          color: dimgray;
        }
        .contents {
          color: rgb(126, 126, 126);
        }

        .button {
          box-shadow: -4px -4px 13px $color-white, 4px 4px 13px $color-shadow;
          transition: all 0.2s ease-in-out;
          border-radius: 10px;
          cursor: pointer;
          text-decoration: none;
          padding: 10px;
          margin-top: 20px;
          margin-right: 10px;
          display: inline-block;
          color: dimgray;
          font-weight: bold;
          &:hover {
            box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
            color: orange;
          }
          &:active {
            box-shadow: inset 1px 1px 2px $color-shadow,
              inset -1px -1px 2px $color-white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .portfolio-section {
    .portfolio-content {
      .portfolio-info-area {
        flex-basis: 100%;
        padding: 10px 20px;
      }
    }
  }
}
