$color-bg: #efeeee;
$color-shadow: #b6b4b4;
$color-white: #fff;
$color-red: #ae1100;
$ruler: 16px;
.footer-section {
  background-color: rgb(112, 112, 112);
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;

  .footer-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;

    .topButton {
      border: none;
      background: none;
      color: white;
      font-size: 25px;
      font-weight: bold;
    }
  }
}
