$color-bg: #efeeee;
$color-shadow: #b6b4b4;
$color-white: #fff;
$color-red: #ae1100;
$ruler: 16px;
header {
  width: 100%;
  background-color: #efeeee;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9;
  display: flex;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);

  a {
    text-decoration: none;
    color: black;
  }

  .navbar {
    width: 100%;
    margin: 0 auto;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 12px;
    box-sizing: border-box;
    height: 60px;

    .navbar__logo {
      font-size: 24px;
      color: #000;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
        -2px -2px 4px rgba(255, 255, 255, 1);
      font-weight: bold;
    }

    .navbar__menu {
      display: flex;
      list-style: none;

      li {
        margin: 0 10px;
        box-shadow: -4px -4px 13px $color-white, 4px 4px 13px $color-shadow;
        transition: all 0.2s ease-in-out;
        border-radius: 10px;

        overflow: hidden;
        button {
          color: dimgray;
          border: none;
          width: 100%;
          height: 100%;
          display: block;
          padding: 8px 12px;
          cursor: pointer;
        }

        &:hover {
          box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
        }
      }
    }
  }
  .toggleBtn {
    right: 15px;
    font-size: 25px;
    display: none;

    box-shadow: -4px -4px 13px $color-white, 4px 4px 13px $color-shadow;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    color: dimgray;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto 0;

    &:hover {
      box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
    }

    &:active {
      box-shadow: inset 1px 1px 2px $color-shadow,
        inset -1px -1px 2px $color-white;
    }
  }
}

.toggle__menu {
  display: none;
}

@media screen and (max-width: 768px) {
  header {
    .navbar {
      .navbar__menu {
        display: none;
      }

      .toggleBtn {
        display: flex;
      }
    }
  }

  .toggle__menu {
    display: flex;
    position: fixed;
    top: 60px;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: #efeeeeea;
    z-index: 9;
    list-style: none;
    align-items: center;

    ul {
      box-shadow: -4px -4px 13px $color-white, 4px 4px 13px $color-shadow;
      margin: 10px;
      list-style: none;
      position: relative;
      padding: 40px 20px;

      border-radius: 10px;
      width: 100%;
      li {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 40px;
        font-size: 24px;

        overflow: hidden;
        border-radius: 10px;
        box-shadow: -4px -4px 13px $color-white, 4px 4px 13px $color-shadow;
        a {
          color: dimgray;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: block;
          padding: 20px;
        }
        button {
          color: dimgray;
          border: none;
          width: 100%;
          height: 100%;
          display: block;
          box-sizing: border-box;
          background-color: transition;
          padding: 20px;
          font-size: 30px;
          border-radius: 10px;
        }

        &:hover {
          box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
